import React from 'react';
import LayoutWrapper from 'components/LayoutWrapper';
import DownloadContainer from 'components/DownloadContainer';
import { CMSWrapper } from 'configs/globalStyle';

const DownloadPage = (): FC => {
  return (
    <LayoutWrapper>
      <CMSWrapper>
        <DownloadContainer />
      </CMSWrapper>
    </LayoutWrapper>
  );
};

export default DownloadPage;
