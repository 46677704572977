import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  h2 {
    font-weight: 700;
    font-size: 43px;
    line-height: 54px;
    font-style: normal;
    letter-spacing: -1px;
    color: #303132;
    top: 90px;
  }
  .img-download-item {
    padding-bottom: 20px;
    padding-top: 30px;
    img {
      width: 222px;
      height: 76px;
    }
  }
`;
