import React from 'react';
import { DownloadWrapper } from './styles';
import downAppStoreSvg from '../../images/icons/down_app_store.svg';
import downCHPlaySvg from '../../images/icons/down_app_chplay.svg';

const DownloadContainer = () => {
  return (
    <DownloadWrapper>
      <h2>Download your "PAV for Vets" app using below links</h2>
            <div className="img-download-item">
              <a href="https://apps.apple.com/us/app/pav-for-vets/id1484582721" target="_blank">
                <img src={downAppStoreSvg} className="img-download-1" />
              </a>
            </div>
            <div className="img-download-item">
              <a href="https://play.google.com/store/apps/details?id=vet.pavforvets.app&hl=en" target="_blank">
                <img src={downCHPlaySvg} className="img-download-2" />
              </a>
            </div>
    </DownloadWrapper>
  );
};

export default DownloadContainer;
